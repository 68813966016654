import  { setTooltip } from '../shared/tooltip'

window.tooltipReference = null
window.hoverable = true

document.addEventListener('turbo:load', () => {
  Array.from(document.querySelectorAll('[data-toggle=svg-tooltip]')).forEach( (elem) => {

    elem.addEventListener('mouseover', () => {
      var tooltips = $('[id^=svg-tooltip]')
      for (let tooltip of tooltips) {
        if(tooltip.id != 'svg-tooltip') {
          $(tooltip).remove()
        }
      }
    }, 0)
  })
})

const tooltipSetter = (tr) => {
  var package_positions = $(tr).find('.ticket-label').attr('data-position')

  if(package_positions) {
    let pkg_positions = package_positions.replace(/'/g, '"')
    pkg_positions = JSON.parse(pkg_positions)

    var seatingChartId = document.getElementsByClassName('seatingChartPackage')[0].getAttribute('data-seating-chart-id')
    pkg_positions = pkg_positions[seatingChartId]

    let keys = Object.keys(pkg_positions)

    if(keys.length > 0) {
      if(keys.length == 1) {
        let tooltips = $('[id^=svg-tooltip]')
        for (let tooltip of tooltips) {
          if(tooltip.id != 'svg-tooltip') {
            $(tooltip).remove()
          }
        }
      }

      let key = keys[0]
      setTooltip(document.getElementById(pkg_positions[key][0]))

      for (let i=0; i<pkg_positions[key].length; i++) {
        let elem = document.getElementById(pkg_positions[key][i])
        elem.style.stroke = '#e10600'
        elem.setAttribute('stroke-width', 3)
      }

      for (let i=1; i<keys.length; i++) {
        key = keys[i]
        setTooltip(document.getElementById(pkg_positions[key][0]), i)
      }
    }
  }
}


$(document)
  .on('mouseover', '.group-package', function () {
    window.tooltipReference = this
    if(window.hoverable)
      tooltipSetter(this)
  })

  .on('mouseleave', '.group-package', function () {
    window.tooltipReference = this
    if(window.hoverable) {
      var package_positions = $(this).find('.ticket-label').attr('data-position')

      if(package_positions) {
        let pkg_positions = package_positions.replace(/'/g, '"')
        pkg_positions = JSON.parse(pkg_positions)

        var seatingChartId = document.getElementsByClassName('seatingChartPackage')[0].getAttribute('data-seating-chart-id')
        pkg_positions = pkg_positions[seatingChartId]

        let keys = Object.keys(pkg_positions)
        let key = keys[0]

        if (pkg_positions[key]) {
          for (let i=0; i<pkg_positions[key].length; i++) {
            let elem = document.getElementById(pkg_positions[key][i])
            elem.setAttribute('stroke-width', 0)
          }
        }
      }
    }

  })
